<template>
    <a href="#" class="dropdown-item" @click.stop.prevent="toggleExtensiones">
        <i class="fas fa-users mr-2"></i> Agenda
    </a>
    <div v-if="showExtensiones">

    <a
        v-for="extension in extensiones"
        :key="extension.extension"
        href="#"
        class="dropdown-item margen-agenda" 
        @click.prevent="llamar(extension.extension)"
    >
        <i class="fas fa-user mr-4"></i> {{ extension.extension }} {{ extension.nombre }}
        <!-- Display the estado if it exists -->
        <span
            class="float-right badge"
            :class="getEstadoClass(extension.estado)"
            style="margin-right: 30px;"
            v-if="extension.estado"
        >
            {{ getEstadoLabel(extension.estado) }}
        </span>
    </a>
    </div>
</template>
<script>
  import { PwgsApi } from '../../../../../services/PwgsApi';

  export default {
    props: {
        extensiones: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showExtensiones: false,
        };
    },
    methods: {
        getEstadoLabel(estado) {
            // Handle different types of estado values
            if (Array.isArray(estado) && estado.length === 0) {
                return 'Desconectado';
            } else if (typeof estado === 'string') {
                if( estado == 'NOT_INUSE'){
                    return 'Disponible';
                } else if (estado == 'UNAVAILABLE'){
                    return 'Desconectado';
                } else if (estado == 'INUSE'){
                    return 'En llamada';
                } else if (estado == 'RINGING'){
                    return 'Sonando';
                }else{
                    return 'Desconocido';
                }
                
            } else if (!estado) {
                return 'Desconocido';
            } else {
                return JSON.stringify(estado);
            }
        },
        getEstadoClass(estado) {
            // Assign classes based on the estado value
            const estadoLabel = this.getEstadoLabel(estado);
            switch (estadoLabel) {
                case 'Disponible':
                    return 'badge-success';
                case 'En llamada':
                    return 'badge-warning';
                case 'Sonando':
                    return 'badge-info';
                case 'Desconectado':
                    return 'badge-danger';
                default:
                    return 'badge-secondary';
            }
        },
        toggleExtensiones() {
            this.showExtensiones = !this.showExtensiones;
        },
        llamar(telefono) {
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = 'tel:' + telefono;
            document.body.appendChild(iframe);

            // Remover el iframe después de un tiempo para limpiar el DOM
            setTimeout(() => {
                document.body.removeChild(iframe);
            }, 1000);
        },

    },
    mounted() {

        const api = new PwgsApi(); 
        api.get('centralita-pwgs/estadosextensiones'); //Solo envía la petición para que el backend envíe por push el estado de las extensiones
        
    }
  }
  </script>
<style scoped>
    .margen-agenda {
        margin-left: 20px;
        
    }
</style>